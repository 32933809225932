import React, { useState } from "react";
import LanguageIcon from "@mui/icons-material/Language";
import { useAirbuzzLocation } from "../../controller/constant/LocationContext";
import { skyExColors } from "../../controller/constant";

const Countries = () => {
  const [isHovered, setHovered] = useState(false);
  const { currentLocation, toggleLocation } = useAirbuzzLocation();

  const handleLocationChange = (e) => {
    toggleLocation();
  };

  return (
    <div
      style={{ display: "flex", alignItems: "center", marginRight: "40px" }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <LanguageIcon
        style={{
          color: isHovered ? "white" : "green",
          fontSize: 35,
          marginRight: "10px",
        }}
      />
      <div
        style={{
          position: "relative",
          display: "inline-block",
        }}
      >
        <select
          value={currentLocation}
          onChange={handleLocationChange}
          style={{
            padding: "5px",
            borderRadius: "5px",
            background: skyExColors.darkPrimary,
            borderColor: "white",
            color: "white",
            fontWeight: "bold",
            width: "100px",
            cursor: "pointer",
          }}
        >
          <option value="United Arab Emirates" style={{ fontWeight: "bold" }}>
            UAE
          </option>
          <option value="BAHRAIN" style={{ fontWeight: "bold" }}>
            BAHRAIN
          </option>
        </select>
      </div>
    </div>
  );
};

export default Countries;
