import { Grid } from "@mui/material";
import React from "react";
import styled from "styled-components";
import LoginForm from "./LoginForm";
import loginImages from "../../../assets/newImages/Login.png";

function Login() {
  return (
    <Wrapper>
      <Grid
        container
        spacing={2}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid
          item
          xl={6}
          lg={6}
          md={12}
          sm={12}
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <LoginForm />
        </Grid>

        <Grid
          item
          xl={6}
          lg={6}
          sx={{
            display: {
              xs: "none",
              sm: "none",
              md: "none",
              lg: "flex",
              xl: "flex",
            },

            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ width: "42vw", height: "100%", maxHeight: "100%" }}>
            <img src={loginImages} style={{ width: "100%" }} alt={"loginImg"} />
          </div>
        </Grid>
      </Grid>
    </Wrapper>
  );
}

export default Login;

const Wrapper = styled.section`
  width: 100%;
  height: 100vh;
  display: flex;
  background-color: #102c38;
  justify-content: center;

  @media (max-width: 600px) {
    padding: 0.5rem;
  }
`;
